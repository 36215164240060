import digitalplatform from '../../../assets/images/digitalplatform.png'

export default function DashboardSection() {
    return (
                
      <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="max-w-xl mx-auto text-center">
            <h2 class="mt-6 text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">The ZAMACE Integrated Digital Platform</h2>
        </div>

        <div class="grid items-center grid-cols-1 mt-12 gap-y-10 lg:grid-cols-5 sm:mt-20 gap-x-4">
            <div class="space-y-8 lg:pr-16 xl:pr-24 lg:col-span-2 lg:space-y-12">
            <p class="mt-4 text-base text-gray-50"></p><p className="mt-4 text-xl text-gray-600 text-left dark:text-black">The ZAMACE Integrated Digital Platform is a secure mobile and web-based platform providing a comprehensive trading, financial services and market information platform covering the complete agricultural marketing system. The platform aims to bring together farmers, commodity buyers, input suppliers, services providers (transporters, storage providers, professional services etc.), extension services providers and financial services providers under one virtual roof.</p>               
            </div>

            <div class="lg:col-span-3">
                <img class="w-full rounded-lg shadow-xl" src={digitalplatform} alt="" />
            </div>
        </div>
    </div>
</section>
    );
}