import React, { useState, useEffect } from 'react';
import { getAllCertifiedWarehouses } from '../../../_helpers/apiService';

const CertifiedWarehouseOperatorsTable = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try
      {
        const data = await getAllCertifiedWarehouses();
        setWarehouses(data);
      } catch (error)
      {
        console.error('Error fetching warehouses:', error);
      }
    };

    fetchData();
  }, []);

  const filteredData = warehouses?.filter((item) => {
    return item.entity_name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="relative overflow-x-auto p-4">
      <div className="bg-white dark:bg-gray-900 p-4">
        <div className="bg-white dark:bg-gray-900 pb-4">
          {/* Search Input */}
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative mt-3">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
              </svg>
            </div>
            <input
              type="text"
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              placeholder="Search for a warehouse"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Table */}
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-4">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Operator Name</th>
              <th scope="col" className="px-6 py-3">Contact Email</th>
              <th scope="col" className="px-6 py-3">Contact Phone</th>
              <th scope="col" className="px-6 py-3">Physical Address</th>
            </tr>
          </thead>
          <tbody>
            {filteredData?.map((warehouse, index) => (
              <React.Fragment key={index}>
                {/* Warehouse Row */}
                <tr className="bg-white text-gray-700 dark:text-white text-md border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-4 py-2">{warehouse.entity_name || '-'}</td>
                  <td className="px-4 py-2">{warehouse.entity_email || '-'}</td>
                  <td className="px-4 py-2">{warehouse.entity_phone || '-'}</td>
                  <td className="px-4 py-2">{warehouse.physical_address ? `${warehouse.physical_address || '-'}, ${warehouse.province || '-'}, ${warehouse.country || '-'}` : "-"}</td>
                </tr>
                {/* Nested Warehouses Table */}
                <tr>
                  <td colSpan="4">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-2">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">Warehouse Name</th>
                          <th scope="col" className="px-6 py-3">Warehouse Address</th>
                          <th scope="col" className="px-6 py-3">Warehouse District</th>
                          <th scope="col" className="px-6 py-3">Warehouse Province</th>
                        </tr>
                      </thead>
                      <tbody>
                        {warehouse.warehouses?.map((nestedWarehouse, warehouseIndex) => (
                          <tr key={warehouseIndex} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td className="px-4 py-2">{nestedWarehouse.name || '-'}</td>
                            <td className="px-4 py-2">{nestedWarehouse.address || '-'}</td>
                            <td className="px-4 py-2">{nestedWarehouse.districts || '-'}</td>
                            <td className="px-4 py-2">{nestedWarehouse.province || '-'}</td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CertifiedWarehouseOperatorsTable;