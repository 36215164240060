import warehouse from '../../../assets/images/warehouse.png'
import warehouse2 from '../../../assets/images/warehouse2.png'

export default function WarehouseCertificationSection() {

    return (
        <section class="bg-gray-50 dark:bg-gray-900">
            <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 class="mt-2 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">Warehouse Inspection & Certification.</h2><br />
                    <p className="mt-6">ZAMACE trains and certifies warehouse operators to manage, grade and store commodities. Secure storage guarantees Q&Q (Quality & Quantity) of stored commodity.</p>
                    <p className="mt-6">Warehouse inspection and certification services, facilitating the development of a warehouse receipt system in Zambia to support the standardised trading platform and to facilitate asset-backed financing in the agricultural sector.</p>
                <div className="flex justify-left items-center">
                        <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                Certification Requirements
              </a>
              <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                Certified Warehouse Operators <span aria-hidden="true">→</span>
              </a>
            </div>
                        </div>
                </div>
                <div class="grid grid-cols-2 gap-4 mt-8">
                    <img class="w-full rounded-lg" src={warehouse} alt="warehouse"></img>
                    <img class="mt-4 w-full lg:mt-10 rounded-lg" src={warehouse2} alt="warehouse alt"></img>
                </div>
            </div>
        </section>

    );
}