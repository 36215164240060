import React from 'react';

const commodityStorageData_ = [{ id: 1, type: 'Silo', location: 'Lusaka', cost: '$10.00' }, { id: 2, type: 'Warehouse', location: 'Lusaka', cost: '$12.00' }, { id: 3, type: 'Silo', location: 'Kitwe', cost: '$11.50' }, { id: 4, type: 'Warehouse', location: 'Kitwe', cost: '$13.50' }, { id: 5, type: 'Silo', location: 'Livingstone', cost: '$12.50' }, { id: 6, type: 'Warehouse', location: 'Livingstone', cost: '$14.50' },];

const commodityStorageData = []

const StorageCosts = () => {
    return (
        <div class="relative overflow-x-auto">
      <div className="p-4 bg-white dark:bg-gray-900">
      <h3 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl dark:text-white">Storage Costs<small class="ml-2 font-thin text-gray-500 dark:text-gray-400">These are indicative costs. Final cost may vary based on service provider</small></h3>
      <br/>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="px-6 py-3">Storage Type</th>
                    <th scope="col" class="px-6 py-3">Location</th>
                    <th scope="col" class="px-6 py-3">Cost tonne/month</th>
                </tr>
            </thead>
            <tbody>
                {commodityStorageData.map((item) => (
                    <tr key={item.id} class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {item.type}
                        </th>
                        <td class="px-6 py-4">{item.location}</td>
                        <td class="px-6 py-4">{item.cost}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </div>
        </div>
    );
};

export default StorageCosts;
