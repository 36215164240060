import React, { useEffect, useState } from 'react';
import Footer from "../../layouts/website/Footer"
import Header from "../../layouts/website/Header"
import MainHeader from "../../layouts/website/warehouses/MainHeader"
import Head from "../../_helpers/Head"
import CertifiedWarehouseOperatorsTable from '../../layouts/website/warehouses/CertifiedWarehouseOperatorsTable';

export default function Warehouses() {
    
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Warehouse Operators" />
                <Header />
                <MainHeader />
            </div>
            <CertifiedWarehouseOperatorsTable />
            <Footer />

        </>
    )
}