import { useState, useEffect } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

const SpotPrices = ({ commodities }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredCommodities = commodities.filter((commodity) =>
    commodity.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div class="relative overflow-x-auto">
      <div className="p-4 bg-white dark:bg-gray-900">
      <h3 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl dark:text-white">Spot Market Prices<small class="ml-2 font-thin text-gray-500 dark:text-gray-400">as at {dateTime.toLocaleDateString()}</small></h3>
      <br/>
        <div class="pb-4 bg-white dark:bg-gray-900">
          <label for="table-search" class="sr-only">Search</label>
          <div class="relative mt-3">
            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
            </div>
            <input
              type="text"
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              placeholder="Search for a commodity"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">Commodity</th>
              <th scope="col" class="px-6 py-3">Price</th>
              <th scope="col" class="px-6 py-3">Unit</th>
              <th scope="col" class="px-6 py-3">Location</th>
              <th scope="col" class="px-6 py-3">Movement</th>
            </tr>
          </thead>
          <tbody>
            {filteredCommodities.map((commodity) => (
              <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600" key={commodity.name}>
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                  {commodity.name}
                </th>
                <td className="px-4 py-2">{commodity.price}</td>
                <td className="px-4 py-2">{commodity.unit}</td>
                <td className="px-4 py-2">{commodity.location}</td>
                <td className="px-4 py-2">
                  {commodity.movement === 'up' ? (
                    <ChevronUpIcon className="h-5 w-5 text-green-500" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5 text-red-500" />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default SpotPrices;
