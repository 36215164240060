
export default function MainHeader() {
    return (
                
<section class="bg-white">
    <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
       
        <h1 className="text-4xl font-bold tracking-tight text-center text-gray-900 sm:text-6xl">
                        Get <span className="text-green-700"> live and historical </span>
                        market data.
                    </h1>
                    <br/>
        <p class="mb-8 text-lg font-thin text-gray-500 lg:text-xl sm:px-16 lg:px-48 dark:text-gray-500">Get live and historical market data, transport differentials, certified warehouse operators and storage costs, market reports and whitepapers.</p>
        
    </div>
    <div class="bg-gradient-to-b from-green-50 to-transparent w-full h-full absolute top-0 left-0 z-0"></div>
</section>

       

    );
}

