import warehousereceipt from '../../../assets/images/warehousereceipt.png'

export default function WarehouseReceiptSection() {
    return (
        <div className="overflow-hidden bg-white py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:pr-8 lg:pt-4">
                        <div className="lg:max-w-lg">
                            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Implementation of the Warehouse Receipt System</p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                            ZAMACE is the Authorized Agency for implementation of the warehouse receipt system under the Zambia Agricultural Credits Act 35 of 2010.
                            </p>
                            <p className="mt-6 text-lg leading-8 text-gray-600">The Warehouse Receipt System (WRS) is defined as a system where commodities can be used as collateral for financing (Inventory Credit).
The issuer of the Warehouse Receipt will certify the deposit of commodities, hold the commodity in safe custody until the depositor or a new ascertained buyer claims the goods.</p>
<p className="mt-6 text-lg leading-8 text-gray-600">ZAMACE maintains a Central Depository or Registry of warehouse receipts which guarantee product quality and quantity.</p>
                        </div>
                    </div>
                    <img
                        src={warehousereceipt}
                        alt="Warehouse Receipt"
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
                        width={2432}
                        height={1442}
                    />
                </div>
            </div>
        </div>
    )
}