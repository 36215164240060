import agrotechnology from '../../../assets/images/agrotechnology.jpeg'

export default function MainHeader() {
    return (
                
      <section class="bg-white">
        <div className="container flex flex-col relative py-8 px-4 mx-auto items-center lg:py-16 z-10 relative">
      <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
              <h1 class="max-w-2xl mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-black">ZAMACE is a <span className="text-green-700"> technology driven </span>
                            Exchange.</h1>
              <p class="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">ZAMACE is committed towards its customers to provide the most advanced & secure electronic platform for commodity trading. The ZAMACE Integrated Digital Platform is the foundation of our business and a key factor in the Exchange’s development.</p>
              
          </div>
          <div class="hidden lg:mt-0 lg:col-span-5  rounded lg:flex">
              <img src={agrotechnology} alt="mockup"></img>
          </div>                
      </div>
      </div>
      <div class="bg-gradient-to-b from-green-50 to-transparent w-full h-full absolute top-0 left-0 z-0"></div>
  </section>
    );
}

