import React, { useState } from "react";


export default function MainHeader() {
  return (

    <div className="bg-white">
      <div className="container flex flex-col relative py-8 px-4 mx-auto items-center lg:py-16 z-10 relative">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center">

        </div>
        <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
          <h1 className="text-4xl font-bold tracking-tight text-center text-gray-900 sm:text-6xl">
            Registered
            <span className="text-green-700"> ZAMACE </span>
            Brokers
          </h1>
          <p className="mt-5 sm:mt-10 lg:w-10/12 text-2xl text-gray-600 dark:text-gray-500 text-center">Registered ZAMACE Brokers facilitate the trading of commodities on the Zambian Commodities Exchange (ZAMACE). They assist buyers and sellers in accessing the market, completing transactions, and securing financing. ZAMACE brokers are required to adhere to the exchange's rules and regulations, prioritizing the best interests of their clients. </p>
        </div>

      </div>
      <div class="bg-gradient-to-b from-green-50 to-transparent w-full h-full absolute top-0 left-0 z-0"></div>
    </div>



  );
}

