import operations from '../../../assets/images/operations.png'

export default function MainHeader() {
    return (
                
      <section class="bg-white">
        <div className="container flex flex-col relative py-8 px-4 mx-auto items-center lg:py-16 z-10 relative">
        <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
      <div class="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div class="mr-auto place-self-center lg:col-span-7">
              <h1 class="max-w-2xl mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-black">Start <span className="text-green-700"> registration </span>
                            to become a member.</h1>
              <p class="mt-6 text-lg leading-8 text-gray-600">ZAMACE requires all firms and individuals that conduct business with the Exchange be registered with it.</p>
              
          </div>
          <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <img src={operations} alt="mockup"></img>
          </div>                
      </div>
      </div>
      </div>
      <div class="bg-gradient-to-b from-green-50 to-transparent w-full h-full absolute top-0 left-0 z-0"></div>
  </section>
    );
}

