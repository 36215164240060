import Footer from "../../layouts/website/Footer";
import Header from "../../layouts/website/Header";
import MainHeader from "../../layouts/website/brokers/MainHeader";
import Head from "../../_helpers/Head";
import RegisteredBrokersTable from "../../layouts/website/brokers/RegisteredBrokersTable";

export default function Brokers () {

    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Brokers" />
                <Header />
                <MainHeader />
            </div>
            {/* <div>
                <nav className="bg-green-100 dark:bg-gray-700">
                    <div className="max-w-screen-xl px-4 py-3 mx-auto">
                        <div className="flex items-center justify-center">
                            <ul className="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
                                <li>
                                    <a href="brokers" className="text-gray-900 dark:text-white hover:underline" aria-current="page">Registered Brokers</a>
                                </li>
                                <li>
                                    <a href="warehouses" className="text-gray-900 dark:text-white hover:underline">Certified Warehouse Operators</a>
                                </li>
                                <li>
                                    <a href="providers" className="text-gray-900 dark:text-white hover:underline">Service Providers</a>
                                </li>
                                <li>
                                    <a href="finance" className="text-gray-900 dark:text-white hover:underline">Financial Institutions</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div> */}
            <RegisteredBrokersTable />
            <Footer />

        </>
    );
}