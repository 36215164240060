import React from "react"
import Footer from "../../layouts/website/Footer"
import Header from "../../layouts/website/Header"
import InvestmentSteps from "../../layouts/website/investors/InvestmentSteps"
import InvestorPremeable from "../../layouts/website/investors/InvestorPremeable"
import MainHeader from "../../layouts/website/investors/MainHeader"
import Head from "../../_helpers/Head"


export default function Investor() {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Investors" />
                <Header />
                <MainHeader />
            </div>
            <InvestorPremeable />
            <InvestmentSteps />
            <Footer />

        </>
    )
}