import React from "react"
import Footer from "../../layouts/website/Footer"
import Header from "../../layouts/website/Header"
import ATSSection from "../../layouts/website/operations/ATSSection"
import MainHeader from "../../layouts/website/operations/MainHeader"
import StandardsandGradingSection from "../../layouts/website/operations/StandardsandGradingSection"
import WarehouseCertificationSection from "../../layouts/website/operations/WarehouseCertificationSection"
import WarehouseReceiptSection from "../../layouts/website/operations/WarehouseReceiptSection"
import Head from "../../_helpers/Head"



export default function Operations() {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Exchange Operations" />
                <Header />
                <MainHeader />
            </div>
            <WarehouseCertificationSection />
            <WarehouseReceiptSection />
            <ATSSection />
            <StandardsandGradingSection />

            <Footer />

        </>
    )
}