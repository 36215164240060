import { CheckIcon } from '@heroicons/react/20/solid'
import stakeholders from '../../../assets/images/stakeholders.jpg'

export default function MainContent() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-green-600">More About Us</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">ZAMACE operates Zambia‘s sole commodity exchange.</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">
              The establishment of ZAMACE was driven by stakeholder demand for a centralised and structured market platform which would help mitigate some of the multiple imperfections in the trade of agricultural commodities.
              </p>
            </div>
          </div>
        </div>
        <div className="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
            src={stakeholders}
            alt="ZAMACE Stakeholders"
          />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              
              <p>The ZAMACE rules-based trading platform was therefore designed to facilitate guaranteed trade and settlement of transactions, and in so doing facilitate anonymous trade which is characteristic of most exchanges. ZAMACE focus areas are;</p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Transparent centralized trading platform.</strong> Facilitating secure sight-unseen trading for price discovery and price referencing.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Warehouse certification</strong> and implementation of the Warehouse Receipt System to enhance market access.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Alternative Dispute Resolution (ADR)</strong> through comprehensive Arbitration Rules which cover all ZAMACE transactions.
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <CheckIcon className="mt-1 h-5 w-5 flex-none text-green-600" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">Commodity Grades and Standards</strong> Ensuring compliance to industry acceptable Grades and Standards and providing market information.
                  </span>
                </li>
              </ul>
              
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">Our Mission.</h2>
              <p className="mt-6">
              Our mission is to provide an efficient and vibrant agricultural commodity exchange, supported by a warehouse certification and receipt system to enhance market access, liquidity and credibility in the commodities market.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
