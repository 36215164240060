import React from 'react';

const TransportDiffs = () => {
  const towns1 = [
    {
      name: 'Livingstone',
      distance: 478,
      cost: 2.5
    },
    {
      name: 'Kitwe',
      distance: 272,
      cost: 2.2
    },
    {
      name: 'Ndola',
      distance: 310,
      cost: 2.0
    },
    {
      name: 'Kabwe',
      distance: 130,
      cost: 1.8
    },
    {
      name: 'Chipata',
      distance: 595,
      cost: 3.0
    }
  ];

  const towns = []

  return (
    <div class="relative overflow-x-auto">
      <div className="p-4 bg-white dark:bg-gray-900">
      <h3 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl dark:text-white">Transport Differentials<small class="ml-2 font-thin text-gray-500 dark:text-gray-400">These are indicative costs. Final cost may vary based on service provider</small></h3>
      <br/>
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Town
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Distance (km)
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Cost per km (USD)
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Total cost (USD)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {towns.map((town, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {town.name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{town.distance}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{town.cost}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{(town.distance * town.cost).toFixed(2)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  );
};

export default TransportDiffs;

