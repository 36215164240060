
import React from 'react';

const SecondaryHeader = () => {
  return (
    <div>
      <nav className="bg-green-100 text-gray-700 dark:bg-gray-600 hidden lg:flex ">
        <div className="max-w-screen-xl px-4 py-3 mx-auto">
          <div className="flex items-center justify-center">
            <ul className="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
              <li>
                <a href="brokers" className="text-gray-900 dark:text-white hover:underline" aria-current="page">Registered Brokers</a>
              </li>
              <li>
                <a href="warehouses" className="text-gray-900 dark:text-white hover:underline">Certified Warehouse Operators</a>
              </li>
              <li>
                <a href="providers" className="text-gray-900 dark:text-white hover:underline">Service Providers</a>
              </li>
              <li>
                <a href="finance" className="text-gray-900 dark:text-white hover:underline">Financial Institutions</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default SecondaryHeader;