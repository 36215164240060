import React, { useState } from 'react';

const data1 = [
  {
    type: 'maize',
    date: '2022-01-01',
    price: 100,
    volume: 500,
  },
  {
    type: 'maize',
    date: '2022-01-02',
    price: 120,
    volume: 700,
  },
  {
    type: 'wheat',
    date: '2022-01-01',
    price: 150,
    volume: 800,
  },
  {
    type: 'wheat',
    date: '2022-01-02',
    price: 170,
    volume: 900,
  },
];

const data = []

const TableRow = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <tr>
      <td className="border px-4 py-2">{data.type}</td>
      <td className="border px-4 py-2">{data.date}</td>
      <td className="border px-4 py-2">{data.price}</td>
      <td className="border px-4 py-2">{data.volume}</td>
      <td className="border px-4 py-2">
        <button onClick={() => setExpanded(!expanded)}>
          {expanded ? '-' : '+'}
        </button>
      </td>
      {expanded && (
        <tr>
          <td colSpan={5} className="border px-4 py-2">
            Additional details here
          </td>
        </tr>
      )}
    </tr>
  );
};

const Table = ({ data }) => {
  const [search, setSearch] = useState('');

  const filteredData = data.filter(
    (item) =>
      item.type.toLowerCase().includes(search.toLowerCase()) ||
      item.date.toLowerCase().includes(search.toLowerCase()) ||
      item.price.toString().includes(search.toLowerCase()) ||
      item.volume.toString().includes(search.toLowerCase())
  );

  return (
    <div class="relative overflow-x-auto">
      <div className="p-4 bg-white dark:bg-gray-900">
      <h3 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl dark:text-white">Historical Data<small class="ml-2 font-thin text-gray-500 dark:text-gray-400">based on trades on ZAMACE</small></h3>
      <br/>
      <input
        type="text"
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead>
          <tr>
            <th className="px-4 py-2">Type</th>
            <th className="px-4 py-2">Date</th>
            <th className="px-4 py-2">Price</th>
            <th className="px-4 py-2">Volume</th>
            <th className="px-4 py-2">Details</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item, index) => (
            <TableRow key={index} data={item} />
          ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};

const CommodityPriceHistory = () => {
  return (
    <div className="container mx-auto">
      <Table data={data} />
    </div>
  );
};

export default CommodityPriceHistory;

