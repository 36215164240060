import React from "react"
import Footer from "../../layouts/website/Footer"
import Header from "../../layouts/website/Header"
import DashboardSection from "../../layouts/website/technology/DashboardSection"
import MainHeader from "../../layouts/website/technology/MainHeader"
import MobileSection from "../../layouts/website/technology/MobileSection"
import Head from "../../_helpers/Head"

export default function Technology() {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Technology" />
                <Header />
                <MainHeader />
            </div>
            <DashboardSection />
            <MobileSection />
            <Footer />

        </>
    )
}