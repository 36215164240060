import maize from '../../../assets/images/maize.png'

export default function MainHeader() {
    return (
        <section class="py-10 sm:py-16 lg:py-24">
            <div class="bg-gradient-to-b from-white to-white">
                <div className="container flex flex-col relative py-8 px-4 mx-auto items-center lg:py-16 z-10 relative">

                    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div class="grid items-center grid-cols-1 gap-8 lg:grid-cols-2">
                            <div>
                                <h1 class="max-w-2xl mb-4 text-4xl font-bold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-black">The ZAMACE<span className="text-green-700"> Exchange </span>
                                    Operations</h1>

                                <p class="mt-8 text-base text-black sm:text-xl">ZAMACE is a transparent market place for the trade of commodities, certification of storage sites, grading and valuation for stored commodities under warehouse receipts, as well as an enabler for the financial sector to finance warehouse receipts.</p>
                            </div>

                            <div>
                                <img class="w-full rounded" src={maize} alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="bg-gradient-to-b from-green-50 to-transparent w-full h-full absolute top-0 left-0 z-0"></div>
        </section>


    );
}

