import grading1 from '../../../assets/images/grading1.png'
import wheat from '../../../assets/images/wheat.jpeg'

export default function StandardsandGradingSection() {

    return (
        <section class="bg-white dark:bg-gray-900">
            <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                    <h2 class="mt-2 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">Commodity Grading & Standards</h2><br />
                    <p className="mt-6">ZAMACE has developed quality standards, acceptable to and recognised by the agricultural industry and Exchange contracts adhere to these standards.</p>
                <div className="flex justify-left items-center">
                        <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href="#"
                className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
              >
                ZAMACE Standard Specifications
              </a>
              
            </div>
                        </div>
                </div>
                <div class="grid grid-cols-2 gap-4 mt-8">
                    <img class="w-full rounded-lg" src={grading1} alt="grading"></img>
                    <img class="mt-4 w-full lg:mt-10 rounded-lg" src={wheat} alt="wheat"></img>
                </div>
            </div>
        </section>

    );
}