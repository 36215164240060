import React from 'react';
import VerticalTab from '../../../_components/VerticalTab';
import SpotPrices from '../../../_components/marketdata/SpotPrices';
import CommodityListing from '../../../_components/marketdata/CommodityListing';
import TransportDiffs from '../../../_components/marketdata/TransportDiffs';
import StorageCosts from '../../../_components/marketdata/StorageCosts';
import CommodityPriceHistory from '../../../_components/marketdata/CommodityPriceHistory';
import storageFees from '../../../_helpers/storageFees.json'
import whrFees from '../../../_helpers/warehouseReceiptCosts.json'
import membershipFees from '../../../_helpers/registrationFees.json'
import WarehouseReceiptCosts from '../../../_components/marketdata/WarehouseReceiptCosts';
import StorageFees from '../../../_components/marketdata/StorageFees';
import ZamaceFeesAndDeposits from '../../../_components/marketdata/ZamaceFeesAndDeposits';
import HorizontalTab from '../../../_components/marketdata/HorizontalTab';

export default function MarketDataTabs() {
  const tabs = ['Market Watch', 'Membership Fees and Deposits', 'Storage Fees', 'Warehouse Receipts Cost', 'Spot Market Price', 'Historical Data', 'Transport Deferentials', 'Storage Costs'];
  const commodities1 = [
    {
      name: 'Maize',
      price: 302.83,
      unit: 'USD/mt',
      location: 'Lusaka',
      movement: 'up',
    },
    {
      name: 'Soybeans',
      price: 0.00,
      unit: 'ZMW/mt',
      location: 'Lusaka',
      movement: 'up',
    },
    {
      name: 'Wheat',
      price: 0.00,
      unit: 'ZMW/mt',
      location: 'Lusaka',
      movement: 'up',
    },
  ];

  //YYYYMMDDHHmmss-UID-COUNTER



  const data = [
    {
      "reference": "20231023094301-ZOFFER-00001",
      "commodity": "SOYA BEANS",
      "quality": "GRADE A",
      "type": "Offer",
      "unit": "Metric Tonnes",
      "unitPrice": "offer",
      "quantity": "10,000",
      "total": "",
      "location": "Mwembeshi",
      "delivery": "Mwembeshi",
      "conditions": "Offer Volume Only. Minimum Purchase of 5,000Mt",
      "status": "close",
      "price": "",
      "date": "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00002",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "7,500",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 5,000Mt",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00002",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "bid",
      quantity: "2,500",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 5,000Mt",
      status: "sold",
      price: "ZMW9,500",
      date: "2023-11-17"
    },
    {
      reference: "20231023094301-ZOFFER-00003",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "10,000",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 5,000Mt",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00004",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "10,000",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 5,000Mt",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00005",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "10,000",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 5,000Mt",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00006",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "10,000",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 5,000Mt",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00007",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "6,250",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 300Mt - 1,000Mt",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00007",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "bid",
      quantity: "150",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 300Mt - 1,000Mt",
      status: "sold",
      price: "ZMW9,500",
      date: "2023-11-15"
    },
    {
      reference: "20231023094301-ZOFFER-00007",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "bid",
      quantity: "300",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 300Mt - 1,000Mt",
      status: "sold",
      price: "ZMW9,500",
      date: "2023-11-15"
    },
    {
      reference: "20231023094301-ZOFFER-00007",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "bid",
      quantity: "300",
      total: "",
      location: "Mwembeshi",
      delivery: "Mwembeshi",
      conditions: "Offer Volume Only. Minimum Purchase of 300Mt - 1,000Mt",
      status: "sold",
      price: "ZMW9,550",
      date: "2023-11-15"
    },
    {
      reference: "20231023094301-ZOFFER-00008",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "5,800",
      total: "",
      location: "Mumbwa",
      delivery: "Mumbwa",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00009",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "bid",
      quantity: "17",
      total: "",
      location: "Chisamba",
      delivery: "Chisamba",
      conditions: "Offer Volume Only. No Part Purchase Allowed",
      status: "sold",
      price: "ZMW9,500",
      date: "2023-11-09"
    },
    {
      reference: "20231023094301-ZOFFER-00010",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "175",
      total: "",
      location: "Kabwe",
      delivery: "Kabwe",
      conditions: "Offer Volume Only. No Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00011",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "5,400",
      total: "",
      location: "Kabwe",
      delivery: "Kabwe",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00012",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "800",
      total: "",
      location: "Kapiri Mposhi",
      delivery: "Kapiri Mposhi",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00013",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "1,900",
      total: "",
      location: "Mkushi",
      delivery: "Mkushi",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00014",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "1,470",
      total: "",
      location: "Luano",
      delivery: "Luano",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "close",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00016",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "800",
      total: "",
      location: "Mazabuka",
      delivery: "Mazabuka",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00017",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "750",
      total: "",
      location: "Kaleya",
      delivery: "Kaleya",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00018",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "1,100",
      total: "",
      location: "Monze",
      delivery: "Monze",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00019",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "960",
      total: "",
      location: "Choma",
      delivery: "Choma",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00020",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "600",
      total: "",
      location: "Kazungula",
      delivery: "Kazungula",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },

    {
      reference: "20231023094301-ZOFFER-00021",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "bid",
      quantity: "1,700",
      total: "",
      location: "Ndola",
      delivery: "Ndola",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "sold",
      price: "ZMW9,500",
      date: "2023-11-20"
    },
    {
      reference: "20231023094301-ZOFFER-00022",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "3,300",
      total: "",
      location: "Chambishi",
      delivery: "Chambishi",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00023",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "380",
      total: "",
      location: "Chingola",
      delivery: "Chingola",
      conditions: "Offer Volume Only. No Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00024",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "3,200",
      total: "",
      location: "Mpongwe",
      delivery: "Mpongwe",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00024",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "bid",
      quantity: "600",
      total: "",
      location: "Mpongwe",
      delivery: "Mpongwe",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "sold",
      price: "ZMW9,750",
      date: "2023-11-27"
    },
    {
      reference: "20231023094301-ZOFFER-00025",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "50",
      total: "",
      location: "Kasama",
      delivery: "Kasama",
      conditions: "Offer Volume Only. No Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00026",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "1,300",
      total: "",
      location: "Lundazi",
      delivery: "Lundazi",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00027",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "29",
      total: "",
      location: "Mwinilunga",
      delivery: "Mwinilunga",
      conditions: "Offer Volume Only. No Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },

    {
      reference: "20231023094301-ZOFFER-00029",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "1,960",
      total: "",
      location: "Mongu",
      delivery: "Mongu",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20231023094301-ZOFFER-00030",
      commodity: "SOYA BEANS",
      quality: "GRADE A",
      type: "Offer",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "800",
      total: "",
      location: "Masaiti",
      delivery: "Masaiti",
      conditions: "Offer Volume Only. Part Purchase Allowed",
      status: "open",
      price: "",
      date: "2023-10-23"
    },
    {
      reference: "20230810123045-ZBID-00001",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "8,000",
      total: "",
      location: "Zambia",
      delivery: "Lusaka",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00002",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "5,000",
      total: "",
      location: "Zambia",
      delivery: "Kabwe",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00003",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "3,000",
      total: "",
      location: "Zambia",
      delivery: "Kapiri Mposhi",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00004",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "7,000",
      total: "",
      location: "Zambia",
      delivery: "Mkushi",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00005",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "2,000",
      total: "",
      location: "Zambia",
      delivery: "Serenje",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00006",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "2,000",
      total: "",
      location: "Zambia",
      delivery: "Mpongwe",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00007",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "3,000",
      total: "",
      location: "Zambia",
      delivery: "Mumbwa",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00008",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "5,000",
      total: "",
      location: "Zambia",
      delivery: "Kitwe",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00009",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "4,000",
      total: "",
      location: "Zambia",
      delivery: "Ndola",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00010",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "8,000",
      total: "",
      location: "Zambia",
      delivery: "Chipata",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00011",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "3,000",
      total: "",
      location: "Zambia",
      delivery: "Katete",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00012",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "4,000",
      total: "",
      location: "Zambia",
      delivery: "Petauke",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00013",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "2,000",
      total: "",
      location: "Zambia",
      delivery: "Mpika",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00014",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "2,000",
      total: "",
      location: "Zambia",
      delivery: "Isoka",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00015",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "2,000",
      total: "",
      location: "Zambia",
      delivery: "Chinsali",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00016",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "3,000",
      total: "",
      location: "Zambia",
      delivery: "Kasama",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00017",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "1,000",
      total: "",
      location: "Zambia",
      delivery: "Kasempa",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00018",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "2,000",
      total: "",
      location: "Zambia",
      delivery: "Solwezi",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00019",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "4,000",
      total: "",
      location: "Zambia",
      delivery: "Choma",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00020",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "3,000",
      total: "",
      location: "Zambia",
      delivery: "Monze",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00021",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "8,000",
      total: "",
      location: "Zambia",
      delivery: "Kalomo",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    },
    {
      reference: "20230810123045-ZBID-00022",
      commodity: "WHITE MAIZE",
      quality: "GRADE A",
      type: "Bid",
      unit: "Metric Tonnes",
      unitPrice: "offer",
      quantity: "1,000",
      total: "",
      location: "Zambia",
      delivery: "Kaoma",
      conditions: "Part offer acceptable, Payment after delivery",
      status: "open",
      price: "",
      date: "2023-08-10"
    }
  ];

  //const data = []
  const commodities = []


  const content = [
    <CommodityListing data={data} />,
    <ZamaceFeesAndDeposits data={membershipFees}/>,
    <StorageFees services={storageFees} />,
    <WarehouseReceiptCosts services={whrFees} />,
    <SpotPrices commodities={commodities} />,
    <CommodityPriceHistory />,
    <TransportDiffs />,
    <StorageCosts />,
  ];

  return (
    <section class="bg-white dark:bg-gray-900">
      <div className="container mx-auto mt-10">
        <HorizontalTab tabs={tabs} content={content} />
      </div>
    </section>
  );
};


