import tradeplatform from '../../../assets/images/tradeplatform.png'
import africatrade from '../../../assets/images/africatrade.png'

export default function ATSSection() {
    return (
        <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
    <div class="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
        <div class="grid items-center md:grid-cols-2 gap-y-10 md:gap-x-20">
            <div class="pr-12 sm:pr-0">
                <div class="relative max-w-xs mb-12">
                    <img class="object-bottom rounded-md" src={tradeplatform} alt="" />

                    <img class="absolute origin-bottom-right scale-75 rounded-md -bottom-12 -right-12" src={africatrade} alt="" />
                </div>
            </div>

            <div>
                <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Auotmated Electronic Trading Platform.</h2>
                <p class="mt-4 text-base leading-relaxed text-gray-600">ZAMACE operates a secure fully automated electronic trading platform, with a central securities (warehouse receipts) depository that enables the electronic management and settlement of all securities traded on the Exchange. </p>
                <p class="mt-4 text-base leading-relaxed text-gray-600">ZAMACE electronic spot trading involves transaction orders for sales and purchases which are in standard lot sizes of standardized ZAMACE commodity grades in the trading system. The ZAMACE automated back office system ensures the existence and validity of the Warehouse Receipt backing the sale, the availability of buyer funds in a deposit account. It is supported by an automated reconciliation system.</p>
            </div>
            
        </div>
    </div>
    
</section>

    )
}