import React from "react"
import Footer from "../../layouts/website/Footer"
import Header from "../../layouts/website/Header"
import MainHeader from "../../layouts/website/company/MainHeader"
import MainContent from "../../layouts/website/company/MainContent"
import AuthorizedAgencyContent from "../../layouts/website/company/AuthorizedAgencyContect"
import KeyFunctions from "../../layouts/website/company/KeyFunctions"
import Head from "../../_helpers/Head"

export default function Company() {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="About Us"/>
                <Header />
                <MainHeader />
            </div>
            <MainContent/>
            <AuthorizedAgencyContent/>
            <KeyFunctions/>
            <Footer/>

        </>
    )
}