import React from "react"
import ContactPage from "../../layouts/website/contact/ContactPage"
import MainHeader from "../../layouts/website/contact/MainHeader"
import Footer from "../../layouts/website/Footer"
import Header from "../../layouts/website/Header"
import Head from "../../_helpers/Head"


export default function Contact() {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Contact Us" />
                <Header />
                <MainHeader />
            </div>
            <ContactPage />
            <Footer />

        </>
    )
}