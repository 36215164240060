import React from 'react';

function VideoComponent ({ src, type, loop }) {
  return (
    <>
      <div className='h-full'>
        {
          type === "iframe" ?
            <>
              <iframe
                src={src}
                className='w-full h-full overflow-hidden rounded-md'
                scrolling="no"
                frameborder="0"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                allowFullScreen="true"
              ></iframe>
            </>
            :
            <>
              <style>
                {`
                video::-internal-media-controls-download-button {
                  display: none;
                }
      
                video::-webkit-media-controls-enclosure {
                  overflow: hidden;
                }
      
                video::-webkit-media-controls-panel {
                  width: calc(100% - 30px); /* Adjust the width as needed */
                }
              `}
              </style>
              <video
                src={src}
                className="w-full h-full rounded-md"
                controls
                loop={loop}
                allowFullScreen
                controlslist="nodownload"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              ></video>
            </>
        }
      </div>
    </>
  );
}

export default VideoComponent;