
export default function MainHeader() {
    return (
<div className="bg-white">
                    <div className="container flex flex-col relative py-8 px-4 mx-auto items-center lg:py-16 z-10 relative">
                        <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
                            <h1 class="text-4xl font-bold tracking-tight text-center text-gray-900 sm:text-6xl">ZAMACE <span className="text-green-700"> Privicy </span>
                            Policy.</h1><br/>                            
                        </div>
                        <p className="mt-5 sm:mt-10 lg:w-10/12 text-2xl text-gray-600 dark:text-white text-center dark:text-black">Privacy Policy for Zambian Commodities Exchange Ltd</p>
                        <div className="flex justify-center items-center">
                        <div className="mt-6 flex items-center justify-center gap-x-6">
              
            </div>
                        </div>
                    </div>
                </div>

       

    );
}

