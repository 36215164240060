
export default function MainHeader() {
    return (

        <div className="bg-white">
            <div className="container flex flex-col relative py-8 px-4 mx-auto items-center lg:py-16 z-10 relative">
                <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
                    <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                        <a href="market-data" class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-black bg-green-100 rounded-full dark:bg-green-900 dark:text-green-300 hover:bg-green-200 dark:hover:bg-green-800">
                            <span class="text-xs bg-red-600 rounded-full text-white px-4 py-1.5 mr-3">Live Data</span> <span class="text-sm font-medium">View live free market data by clicking here</span>
                            <svg aria-hidden="true" class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                        </a>
                    </div>
                    <h1 class="text-4xl font-bold tracking-tight text-center text-gray-900 sm:text-6xl">Visit or Contact <span className="text-green-700"> ZAMACE </span>
                        Exchange.</h1><br />
                    <h2 class="mt-6 text-lg leading-8 text-gray-600">ZAMACE operates Zambia‘s sole commodity exchange. Get in touch today</h2><br />
                    <form class="w-full max-w-md mx-auto">
                        <label for="default-email" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Email sign-up</label>
                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                                    <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                </svg>
                            </div>
                            <input type="email" id="default-email" class="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-white focus:ring-green-500 focus:border-green-500 dark:bg-gray-800 dark:border-gray-700 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500" placeholder="Enter your email here..." required />
                            <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">Join Mailing List</button>
                        </div>
                    </form>
                </div>
                <div className="flex justify-center items-center">
                    <div className="mt-6 flex items-center justify-center gap-x-6">

                    </div>
                </div>
            </div>
            <div class="bg-gradient-to-b from-green-50 to-transparent w-full h-full absolute top-0 left-0 z-0"></div>
        </div>



    );
}
