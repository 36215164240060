import React from "react"
import Footer from "../../layouts/website/Footer"
import Header from "../../layouts/website/Header"
import MainHeader from "../../layouts/website/membership/MainHeader"
import MemberTypes from "../../layouts/website/membership/MemberTypes"
import RegistrationSteps from "../../layouts/website/membership/RegistrationSteps"
import Head from "../../_helpers/Head"

export default function Membership() {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Membership" />
                <Header />
                <MainHeader />
            </div>
            <MemberTypes />
            <RegistrationSteps />
            <Footer />

        </>
    )
}

