import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

const features = [
    {
        name: 'Create Access Account.',
        description:
            'This is the first step in the registration process. This entails providing your personal details including full names, email address and access password. You will be required to successfully verify your email address before moving to the next stage.',
        icon: CheckIcon,
    },
    {
        name: 'Select Membership Type.',
        description: 'You can register as Broker, Cooperative, Warehouse Operator, Service Provider or Investor. Select your preferred membership type at this stage. Note that after your intial registration, you may add other membership types to your membership profile.',
        icon: CheckIcon,
    },
    {
        name: 'Provide Official Registration Information on Entity.',
        description: 'Provide information such as company name and address, company registration details, tax registration details, shareholder and directorship details and other related details for the entity you intend to register as a ZAMACE member. Note that if the entity is already registered with ZAMACE, you will be notified to contact the primary access account owner to provide you access to the entity ZAMACE profile.',
        icon: CheckIcon,
    },
    {
        name: 'Provide Membership Type Related Details.',
        description: 'This is information unique to the type of membership selected. fro instance if you are registering as a Warehouse Operator, you will be asked to list your warehouse locations and storage capacities. If you are registering as a Broker you will be asked to provide details of personnel authorised to trade on ZAMACE on behalf of the company.',
        icon: CheckIcon,
    },
    {
        name: 'Verification and Clearance of Registration.',
        description: 'At this stage ZAMACE performs a verification process of your registration including background checks and validation of the information provided in your registration. This process may take up to 14 (fourteen) days to be completed depending on the membership registration type. At this stage ZAMACE may contact you for any additional infomration or clarifications that may be required.',
        icon: CheckIcon,
    },
    {
        name: 'ZAMACE Membership ID and Certificate Issued.',
        description: 'If the verification process is successful ZAMACE will issue your entity with a membership certificate and ZAMACE Member ID concluding your registration process. A this point you are a registered member of ZAMACE.',
        icon: CheckIcon,
    },
]



export default function RegistrationSection() {

    return (
        <section class="bg-white">
            <div class="grid grid-cols-1 lg:grid-cols-2">
                <div class="flex items-center justify-center px-4 py-10 bg-gray-50 sm:px-6 lg:px-8 sm:py-16 lg:py-24">
                    <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <div class="max-w-2xl mx-auto text-center">
                            <h2 class="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">Registration procedure</h2>
                            <p class="max-w-lg mx-auto mt-4 text-base leading-relaxed text-black">ZAMACE membership is open to individuals or corporate entities looking to participate in the commodities market in Zambia and the African continent. The registration process is as follows;</p>
                        </div>

                        <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                            {features.map((feature) => (
                                <div key={feature.name} className="relative pl-9">
                                    <dt className="inline font-semibold text-gray-900">
                                        <feature.icon className="absolute left-1 top-1 h-5 w-5 text-green-600" aria-hidden="true" />
                                        {feature.name}
                                    </dt>{' '}
                                    <dd className="inline">{feature.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>

                </div>

                <div className="bg-white">
                    <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8">
                        <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-16 shadow-2xl sm:rounded-3xl sm:px-16 md:pt-24 lg:flex lg:gap-x-20 lg:px-24 lg:pt-0">
                            <svg
                                viewBox="0 0 1024 1024"
                                className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
                                aria-hidden="true"
                            >
                                <circle cx={512} cy={512} r={512} fill="url(#759c1415-0410-454c-8f7c-9a820de03641)" fillOpacity="0.7" />
                                <defs>
                                    <radialGradient id="759c1415-0410-454c-8f7c-9a820de03641">
                                        <stop stopColor="#6DBE45" />
                                        <stop offset={1} stopColor="#2E8B57" />
                                    </radialGradient>
                                </defs>
                            </svg>

                            <div className="mx-auto max-w-md text-center lg:mx-0 lg:flex-auto lg:py-32 lg:text-left">
                                <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                                    Become a ZAMACE member today!</h2>
                                <p className="mt-6 text-lg leading-8 text-gray-300">
                                    ZAMACE registration is a seamless and efficient online process designed to streamline the registration of market participants in the Zambian Agricultural Commodity Exchange. Through the user-friendly online platform, individuals and organizations can easily access and complete the registration formalities from the comfort of their own devices.
                                </p>
                                <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
                                    <a
                                        href="https://dashboard.zamace.co.zm/register"
                                        className="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
                                    >
                                        Start Registration
                                    </a>
                                    <a href="https://dashboard.zamace.co.zm/" className="text-sm font-semibold leading-6 text-white">
                                        Already a Member? Login <span aria-hidden="true">→</span>
                                    </a>
                                </div>
                                <br />
                            </div>

                        </div>
                    </div>

                    <div className="mx-auto mt-16 max-w-2xl rounded-3xl sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
                        <div className="p-8 sm:p-10 lg:flex-auto">
                            <h3 className="text-2xl font-bold tracking-tight text-gray-900">Need help registering for ZAMACE?</h3>
                            <p className="mt-6 text-base leading-7 text-gray-600">
                                For individuals or businesses seeking assisted registration onto the ZAMACE platform, we have put in place dedicated support to make the process seamless for you. Please reach out to our ZAMACE Call Center or email us.
                            </p>

                        </div>
                        <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
                            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
                                <div className="mx-auto max-w-xs px-8">
                                    <h2 className="text-base font-semibold text-gray-600">Call Us</h2>
                                    <p className="mt-6 flex items-baseline justify-center gap-x-2">
                                        <span className="text-2xl font-bold tracking-tight text-gray-900">+260 960 432 266</span>
                                    </p>
                                    <a
                                        href="mailto:register@zamace.co.zm"
                                        className="mt-10 block w-full rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Enquire via Email
                                    </a>
                                    <p className="mt-6 text-xs leading-5 text-gray-600">
                                        All email enquries regarding registration should be sent to register@zamace.co.zm
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



    )
}