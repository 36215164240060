
import soyabeans1 from '../../../assets/images/soybeans1.jpg'
import soyabeans2 from '../../../assets/images/soyabeans.jpeg'


export default function InvestorPremeable() {
    return (
        <section class="bg-gray-50 dark:bg-gray-900">
    <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 class="mt-2 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">ZAMACE provides various services to investors electronically through its online platform set for this purpose.</h2><br/>
            <p className="mt-6">ZAMACE offers a tele-trading service to allow investors to participate in the exchange remotely. It is important to note that investing in commodities involves risks and you should consult with a financial advisor before making any investment decisions.</p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8">
            <img class="w-full rounded-lg" src={soyabeans1} alt="soyabeans"></img>
            <img class="mt-4 w-full lg:mt-10 rounded-lg" src={soyabeans2} alt="soyabeans"></img>
        </div>
    </div>
</section>
    )
}