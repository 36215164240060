
export default function MainContent() {
  return (
    <section class="bg-gray-50 dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div class="max-w-screen-lg">
                <h2 class="mb-4 text-2xl tracking-tight font-bold text-gray-900 dark:text-white">Get live and historical market data, transport differentials, certified warehouse operators and storage costs, market reports and whitepapers.</h2>
                <div class="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                    
                    <a href="#" class="inline-flex items-center justify-center px-2 py-2.5 text-base font-medium text-center text-green-900 border border-green-300 rounded-lg hover:bg-green-200 focus:ring-4 focus:ring-green-100 dark:text-white dark:border-green-600 dark:hover:bg-green-700 dark:focus:ring-green-600">
                        Commodity Grading & Standards 
                    </a>
                    <a href="#" class="inline-flex items-center justify-center px-2 py-2.5 text-base font-medium text-center text-green-900 border border-green-300 rounded-lg hover:bg-green-200 focus:ring-4 focus:ring-green-100 dark:text-white dark:border-green-600 dark:hover:bg-green-700 dark:focus:ring-green-600">
                      Certified Warehouses  
                    </a>
                    <a href="#" class="inline-flex items-center justify-center px-2 py-2.5 text-base font-medium text-center text-green-900 border border-green-300 rounded-lg hover:bg-green-200 focus:ring-4 focus:ring-green-100 dark:text-white dark:border-green-600 dark:hover:bg-green-700 dark:focus:ring-green-600">
                      Reports & Whitepapers  
                    </a>   
                </div>
            </div>
        </div>
    </section>
   

  )
}

