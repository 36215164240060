import zamacebanners from '../../../assets/images/zamacebanners.jpg'
import zamaceicon from '../../../assets/images/zamaceicon.png'

export default function AuthorizedAgencyContent() {
    return (
        <section class="bg-white dark:bg-gray-900">
    <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 class="mt-2 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">We connect commodity buyers and sellers, supported by world-class technology and regulatory oversight.</h2><br/>
            <p className="mt-6">By 2011, ZAMACE had developed a set of grades and standards that were – and still are – adopted by the agricultural industry, it had implemented a pilot warehouse certification process, developed and utilised an arbitration process in resolving trading disputes and traded over $80m of commodities.</p>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-8">
            <img class="w-full rounded-lg" src={zamacebanners} alt="office content 1"></img>
            <img class="mt-4 w-full lg:mt-10 rounded-lg" src={zamaceicon} alt="office content 2"></img>
        </div>
    </div>
</section>
    )
}