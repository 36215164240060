
export default function KeyFunctions() {
    return (
        <section class="bg-gray-50 dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
            <div class="max-w-screen-lg">
                <h2 class="mb-4 text-3xl tracking-tight font-bold text-gray-900 dark:text-white">ZAMACE is the Authorized Agency for implementation of the warehouse receipt system under the Zambia Agricultural Credits Act 35 of 2010.</h2>
                <p class="mb-8 font-light text-gray-500 sm:text-xl dark:text-gray-400">The trading platform is a transparent market place for the trade of commodities, certification of storage sites, grading and valuation for stored commodities under warehouse receipts, as well as an enabler for the financial sector to finance warehouse receipts.</p>
                <div class="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-4">
                    <a href="#" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        Storage Site Certification
                    </a>
                    <a href="#" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        Warehouse Receipt Issuance 
                    </a>
                    <a href="#" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        Automated Trading Platform
                    </a>
                    <a href="#" class="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        Market Information
                    </a>   
                </div>
            </div>
        </div>
    </section>
    )
}