import React, { useState } from "react";

export default function MainHeader() {
    return (

        <div className="bg-gray-100">
            <div className="container relative isolate mx-auto flex flex-col items-center py-8 sm:py-24">
                <div className="hidden sm:mb-8 sm:flex sm:justify-center">
                    <a href="market-data" class="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-black bg-green-100 rounded-full dark:bg-green-900 dark:text-green-300 hover:bg-green-200 dark:hover:bg-green-800">
                        <span class="text-xs bg-red-600 rounded-full text-white px-4 py-1.5 mr-3">Live Data</span> <span class="text-sm font-medium">View live free market data by clicking here</span>
                        <svg aria-hidden="true" class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                    </a>
                </div>
                <div className="w-11/12 sm:w-2/3 lg:flex justify-center items-center flex-col  mb-5 sm:mb-10">
                    <h1 className="text-4xl font-bold tracking-tight text-center text-gray-900 sm:text-6xl">
                        The <span className="text-green-700"> Zambian Commodities Exchange </span>
                        is a dynamic digital, commodity exchange focused on revolutionising Zambia’s and Africa's agricultural sector.
                    </h1>
                    <p className="mt-5 sm:mt-10 lg:w-10/12 text-2xl text-thin text-gray-600 dark:text-black text-center dark:text-black">ZAMACE is committed to the development of the whole agricultural ecosystem. Our is aim to connect the primary producer to the Exchange thereby paving the way for sustainable and inclusive growth of Zambia and the region's agrarian economy.</p>
                </div>
                <div className="flex justify-center items-center">
                    <div className="mt-10 flex items-center justify-center gap-x-6">

                    </div>
                </div>
            </div>
        </div>


    );
}

