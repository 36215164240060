import React, { useState } from 'react';

const FilteredBrokers = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredBrokerData = data?.filter((item) =>
    item?.entity_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  return (
    <div>
      <div className="bg-white dark:bg-gray-900 pb-4">
        <label htmlFor="table-search" className="sr-only">Search</label>
        <div className="relative mt-3">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
            </svg>
          </div>
          <input
            type="text"
            className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
            placeholder="Search for a broker"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-4">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">Broker Name</th>
            <th scope="col" className="px-6 py-3">Broker Email</th>
            <th scope="col" className="px-6 py-3">Broker Phone</th>
            <th scope="col" className="px-6 py-3">Physical Address</th>
          </tr>
        </thead>
        <tbody>
          {filteredBrokerData?.map((item, index) => (
            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td className="px-4 py-2">{item.entity_name || '-'}</td>
              <td className="px-4 py-2">{item.entity_email || '-'}</td>
              <td className="px-4 py-2">{item.entity_phone || '-'}</td>
              <td className="px-4 py-2">{item.physical_address ? `${item.physical_address || '-'}, ${item.province || '-'}, ${item.country || '-'}` : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FilteredBrokers;