import React from "react"
import MainHeader from "../../../layouts/dashboard/register/MainHeader"
import RegistrationSection from "../../../layouts/dashboard/register/RegistrationSection"
import Footer from "../../../layouts/website/Footer"
import Header from "../../../layouts/website/Header"
import Head from "../../../_helpers/Head"


export default function Register() {
    return (
        <>
            <div class="flex flex-col space-y-20 ...">
                <Head title="Start Registration" />
                <Header />
                <MainHeader />
            </div>
            <RegistrationSection />
            <Footer />

        </>
    )
}